import React, { memo } from "react";
import { Handle, Position } from "reactflow";

export default memo(({ data, isConnectable }) => {
  return (
    <>
      { !data.social && (
        <Handle
          type="target"
          position={Position.Left}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={isConnectable}
        />
      )}
      <div 
        className="element-landingpage"
        style={{
          backgroundImage: `url(${data.image.url})`,
          height: data.image.height,
          width: data.image.width
        }}
      ></div>
      { data.label && (
        <span className="element__title">{data.label}</span>
      )}
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        isConnectable={isConnectable}
      />
    </>
  );
});
