// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { useEffect, useCallback, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  Controls,
  Background
} from 'reactflow'
import 'reactflow/dist/style.css'

import './Funneldraw.scss'

import ImageNode from './ImageNode'

import landingPage from './landing-page.svg';
import showroom from './showroom.svg';
import obrigado from './obrigado.svg';
import upsell from './upsell.svg';
import orderBump from './order-bump.svg';

import facebook from './facebook.svg';
import instagram from './instagram.svg';
import googleAds from './google-ads.svg';
import radio from './radio.svg';
import tv from './tv.svg';

import comprar from './comprar.svg';

import Sidebar from './Sidebar';

const connectionLineStyle = { stroke: "#fff" };
const snapGrid = [20, 20];
const nodeTypes = {
  imageNode: ImageNode
};

const defaultViewport = { x: 0, y: 0, zoom: 0.5 };

const getId = () => `${uuidv4()}`;

const Funneldraw = props => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(props.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(props.edges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  useEffect(() => {
    document.getElementById('funnel-nodes').value = JSON.stringify(nodes);
    document.getElementById('funnel-edges').value = JSON.stringify(edges);
  }, [nodes, edges]);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({
      ...params, 
      animated: true, 
      style: { stroke: "#000" }
    }, eds)),
    [],
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const type = event.dataTransfer.getData('application/reactflow');
      const dataNode = JSON.parse(event.dataTransfer.getData('application/reactflow-data'));

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: dataNode,
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance],
  );

  return (
    <div>
      <div className="dndflow">
        <ReactFlowProvider>
          <Sidebar />
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              nodeTypes={nodeTypes}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              snapToGrid={true}
              snapGrid={snapGrid}
              defaultViewport={defaultViewport}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              fitView
              attributionPosition="bottom-left"
            >
              <Controls />
              <Background />
            </ReactFlow>
          </div>
        </ReactFlowProvider>
      </div>
    </div>
  )
}

Funneldraw.defaultProps = {
  name: 'Funnel',
  nodes: [],
  edges: []
}

Funneldraw.propTypes = {
  name: PropTypes.string,
  nodes: PropTypes.array,
  edges: PropTypes.array
}

document.addEventListener('DOMContentLoaded', () => {
  const elFunnelDraw = document.getElementById("funneldraw");

  if (elFunnelDraw) {
    let nodes = document.getElementById('funnel-nodes').value.trim();
    let edges = document.getElementById('funnel-edges').value.trim();

    if (nodes === '') {
      nodes = []
    } else {
      nodes = JSON.parse(nodes)
    }

    if (edges === '') {
      edges = []
    } else {
      edges = JSON.parse(edges)
    }

    ReactDOM.render(
      <Funneldraw name="Funnel" nodes={nodes} edges={edges} />,
      elFunnelDraw,
    )
  }
})
